import React from 'react';

import { ReactComponent as Lemp } from './icons/lemp.svg';
import { ReactComponent as Lamp } from './icons/lamp.svg';
import { ReactComponent as cPanel } from './icons/cpanel.svg';


export const vpsBoxData = {
	heading: 'We will help you night and day.',
	subheading:
		'Your AWS server is already secure, fast, and best-in-class. Add a team of professional admins to build and monitor your new server.',
	boxes: [
		{
			icons: [Lemp],
			planLabel: 'VPS A',
			planSlogan: 'FROM',
			amount: '$17.95',
			duration: 'mo',
			sale: 'Amazon EC2 Bare Metal',
			saleTm: 'Renews at same price',
			linkInternal: true,
			link: '/vps/?appType=0&app=2',
			linkLabel: 'Continue',
			features: [
				{ count: 'Amazon Linux 2023', label: '— Amazon\'s native linux with support through 2028' },
				{ count: 'Fully Managed', label: '- VPS EC2 Bare Metal' },
				{ count: 'Static Amazon IP', label: '— Global Leader' },
				{ count: '2 Cores', label: '— Intel® Xeon® CPU' },
				{ count: 'Up to 8 GB', label: '— Guaranteed RAM' },
				{ count: 'Up to 160 GB', label: '— NVMe SSD Storage' },
				{ count: 'Up to 5TB', label: '— AWS Data Bandwidth' },
				{ count: 'SSL Included', label: '— Set up on custom domain' },
				{ count: 'Automated Backups', label: '— Optional' },
				{ count: 'Take snapshots', label: '— Optional' },
				{ count: 'Data Protection', label: '— security by Amazon' },
			],
			footer: (
				<>
					<strong>Premium Support</strong> — 24/7 × 365 days 
				</>
			),
		},
		{
			icons: [cPanel],
			planLabel: 'VPS B',
			planSlogan: 'FROM',
			amount: '$22.95',
			duration: 'mo',
			sale: 'Amazon EC2 Bare Metal',
			saleTm: 'Renews at same price',
			linkInternal: true,
			link: '/vps/?appType=0&app=0',
			linkLabel: 'Continue',
			features: [
				{ count: 'cPanel/WHM', label: '— 14-Day Trial Then Bring Your Own License from cPanel' },
				{ count: 'Fully Managed', label: '- VPS EC2 Bare Metal' },
				{ count: 'Static Amazon IP', label: '— Global Leader' },
				{ count: 'Up to 4 Cores', label: '— Intel® Xeon® CPU' },
				{ count: 'Up to 16 GB', label: '— Guaranteed RAM' },
				{ count: 'Up to 320 GB', label: '— NVMe SSD Storage' },
				{ count: 'Up to 6TB', label: '— AWS Data Bandwidth' },
				{ count: 'SSL Included', label: '— Set up on custom domain' },
				{ count: 'Automated Backups', label: '— Optional' },
				{ count: 'Take snapshots', label: '— Optional' },
				{ count: 'Data Protection', label: '— security by Amazon' },
			],
			footer: (
				<>
					<strong>Premium Support</strong> — 24/7 × 365 days 
				</>
			),
		},
		{
			icons: [Lamp],
			planLabel: 'VPS C',
			planSlogan: 'FROM',
			amount: '$22.95',
			duration: 'mo',
			sale: 'Amazon EC2 Bare Metal',
			saleTm: 'Renews at same price',
			linkInternal: true,
			link: '/vps/?appType=0&app=1',
			linkLabel: 'Continue',
			features: [
				{ count: 'Plesk', label: '— 1 Month Trial Then Bring Your Own License from Plesk' },
				{ count: 'Fully Managed', label: '- VPS EC2 Bare Metal' },
				{ count: 'Static Amazon IP', label: '— Global Leader' },
				{ count: 'Up to 4 Cores', label: '— Intel® Xeon® CPU' },
				{ count: 'Up to 16 GB', label: '— Guaranteed RAM' },
				{ count: 'Up to 320 GB', label: '— NVMe SSD Storage' },
				{ count: 'Up to 6TB', label: '— AWS Data Bandwidth' },
				{ count: 'SSL Included', label: '— Set up on custom domain' },
				{ count: 'Automated Backups', label: '— Optional' },
				{ count: 'Take snapshots', label: '— Optional' },
				{ count: 'Data Protection', label: '— security by Amazon' },
			],
			footer: (
				<>
					<strong>Premium Support</strong> — 24/7 × 365 days 
				</>
			),
		},
	],
};
