import React from 'react';
import { ReactComponent as Backups } from './icons/backups.svg';
import { ReactComponent as ControlPanel } from './icons/control-panels.svg';
import { ReactComponent as FreeSsl } from './icons/free-ssl.svg';
import { ReactComponent as GlobalDataCenter } from './icons/global-datacenters.svg';
import { ReactComponent as InstantSetup } from './icons/instant-setup.svg';
import { ReactComponent as ManagedWordpress } from './icons/managed-wordpress.svg';
import { ReactComponent as Monitoring } from './icons/monitoring.svg';
import { ReactComponent as Performance } from './icons/performance.svg';
import { ReactComponent as RootAccess } from './icons/root-access.svg';
import { ReactComponent as Scalability } from './icons/scalability.svg';
import { ReactComponent as Security } from './icons/security.svg';
import { ReactComponent as Support } from './icons/support.svg';
import { ReactComponent as UnlimitedTraffic } from './icons/unlimited-traffic.svg';
import { ReactComponent as Uptime } from './icons/uptime.svg';
import { ReactComponent as PlayCircle } from '../../../src/svgs/icons/play-circle.svg';

const solidFeaturesData = {
	heading: 'Trusted by hundreds of small and medium businesses',
	linkIcon: <PlayCircle className="hostjane-main-SolidFeatures__link-icon" />,
	features: [
		{
			icon: <Support className="hostjane-main-SolidFeatures__icon" />,
			title: 'Instant delivery',
			description:
				'We configure AWS servers to privately post, as they launch, their Amazon IP and login credentials to your account email.',
			link: 'cPanel VPS from $22.95/mo',
			linkHref: '/vps/?appType=0&app=0',
		},
		{
			icon: (
				<ControlPanel className="hostjane-main-SolidFeatures__icon" />
			),
			title: 'Run for you',
			description:
				'Set up and full management of your linux or windows server, including incident response, monitoring, all updates and maintenance.',
		},
		{
			icon: <Monitoring className="hostjane-main-SolidFeatures__icon" />,
			title: 'Data protection',
			description:
				'AWS is a global leader with up to 10-Gbps network performance. Your server is secured by and safer with AWS than any other web host.',
		},
		{
			icon: (
				<ManagedWordpress className="hostjane-main-SolidFeatures__icon" />
			),
			title: 'Trial licensing',
			description:
				'Your server includes a trial license from cPanel or Plesk. Bring or buy your own license from cPanel or Plesk to keep using the AWS server.',
		},
		{
			icon: (
				<UnlimitedTraffic className="hostjane-main-SolidFeatures__icon" />
			),
			title: 'Built for high traffic',
			description:
				'AWS offers massive bandwidth included, enough for most companies running intensive apps.',
		},
		{
			icon: <Backups className="hostjane-main-SolidFeatures__icon" />,
			title: 'Anti DDos Defense',
			description:
				'Protect your data with AWS\'s state-of-the-art denial-of-service (DDoS) attacks included with all plans.',
		},
		{
			icon: (
				<InstantSetup className="hostjane-main-SolidFeatures__icon" />
			),
			title: 'Expert setup',
			description:
				'Configured by a qualified RMIT programmer, your new virtual private server on Amazon EC2 is ready for production.',
		},
		{
			icon: <Security className="hostjane-main-SolidFeatures__icon" />,
			title: 'Malware scanning',
			description:
				"Your hardened server will get HostJane server monitoring and datacenter-level monitoring by AWS.",
		},
		{
			icon: <Scalability className="hostjane-main-SolidFeatures__icon" />,
			title: 'Scalable VPS',
			description:
				'Upscaling and changing your plan is a higher RAM, CPU and SSD storage volume is done overnight. ',
		},
		{
			icon: (
				<GlobalDataCenter className="hostjane-main-SolidFeatures__icon" />
			),
			title: 'AWS datacenter',
			description:
				'AWS\'s physical security and worldclass infrastructure will stop you losing business to downtime.',
		},
		{
			icon: <RootAccess className="hostjane-main-SolidFeatures__icon" />,
			title: 'You have full control',
			description:
				"We\'ll manage your linux or windows virtual machine. You have control over your website with your web panel of choice.",
		},
		{
			icon: <FreeSsl className="hostjane-main-SolidFeatures__icon" />,
			title: 'SSL Included',
			description:
				"Enter your domain name in checkout and we\'ll automatically install your free SSL certificate.",
			link: 'Point your domain to server',
			linkHref:
				'https://help.hostjane.com/point-your-domain',
		},
		{
			icon: <Uptime className="hostjane-main-SolidFeatures__icon" />,
			title: '99.9% uptime',
			description:
				'HostJane offers a 99.9% uptime guarantee matched to AWS SLA who own the servers.',
			link: 'View our SLA ',
			linkHref: '/legal/sla/',
		},
		{
			icon: <Performance className="hostjane-main-SolidFeatures__icon" />,
			title: 'Always up-to-date',
			description:
			  'Your AWS VPS is automatically updated to the latest version of web server software and applications.',
		},
	],
};

export default solidFeaturesData;
